import React, { useState } from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Stack from 'react-bootstrap/Stack'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Carousel from 'react-bootstrap/Carousel'
import LinkedIn from '../assets/LI-In-Bug.png'
import Facebook from '../assets/fb.png'
import ATALogo from '../assets/ATA_Logo_2.png'
import AnchoringPens from '../assets/anchoring_pens.jpg'

const RootIndex = (props) => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [name, setName] = useState('')
  const [subject, setSubject] = useState('')

  let params = subject || message ? '?' : ''
  if (subject)
    params += `subject=${encodeURIComponent(`${subject} from ${name}`)}`
  if (message)
    params += `${subject ? '&' : ''}body=${encodeURIComponent(message)}`

  const heroImage1 =
    'https://images.ctfassets.net/ktvuei6pywef/2fwq5TP44M9fGsIgmmZd0K/cfc156d02920a6bdb5494ac26cd6a42b/ParGlobal_HeaderPhoto.jpg?w=1180'
  const heroImage2 =
    'https://images.ctfassets.net/ktvuei6pywef/11nqvq7oceA4FwA4hrOzCr/09012a0be581e09366f35d22c96f56ff/HeaderImage_2.png?w=1180'
  const heroImage3 =
    'https://images.ctfassets.net/ktvuei6pywef/7Yw589aYez7gyl8Ba2TUW/deff7e9a38cd5e632673d53a5c73fe29/HeaderImage_3.png?w=1180'

  return (
    <Layout location={props.location}>
      <Carousel>
        <Carousel.Item>
          <img className="d-block w-100" src={heroImage1} alt="First slide" />
          <Carousel.Caption>
            <div className="text-container">
              <h1>We help you pave the way</h1>
              <p>
                Through research and technology, we continue to foster
                innovation for new and improved environmentally-safe products.
              </p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={heroImage2} alt="Second slide" />

          <Carousel.Caption>
            <div className="text-container">
              <h1>Complete solutions</h1>
              <p>Ranging from road construction to modern agriculture</p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={heroImage3} alt="Third slide" />

          <Carousel.Caption>
            <div className="text-container">
              <h1>Large clientele base</h1>
              <p>Across USA, Canada, India and Australia</p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <Container className="section">
        <Stack direction="horizontal" gap={3}>
          <div className="">
            <h1>Featured Products</h1>
          </div>
          <div className="ms-auto">
            <Link to={`/products`}>VIEW ALL</Link>
          </div>
        </Stack>
        <Row className="text-center">
          <Col xs={12} sm={4}>
            <img
              className="mt-3 mb-3"
              alt="Silt Fence"
              src="https://images.ctfassets.net/ktvuei6pywef/7anC9HxBmR9reYywQKPsEJ/54ed2a85e5c5d79640c6b2d97fad5d85/SILT-FENCE.jpeg?w=768&h=576&q=50&fm=webp"
            />
            <h5>Silt Fence</h5>
            <p>
              For applications in construction industries, paved/unpaved
              roadways, haul roads for logging/landfills, temporary construction
              entrances, etc.
            </p>
            <Link to={`/products#silt-fence`}>
              <Button size="sm" className="button-gold">
                READ MORE
              </Button>
            </Link>
          </Col>
          <Col xs={12} sm={4}>
            <img
              className="mt-3 mb-3"
              alt="Woven Coated PE Geo-Membrane"
              src="https://images.ctfassets.net/ktvuei6pywef/DwDBdrwRrmBoVothPmA3x/80a7edccfff8927b2da688a175b037ae/woven_coated.jpg?w=640&h=480&q=50&fm=webp"
            />
            <h5>Woven Coated PE Geo-Membrane</h5>
            <p>
              Medium to Heavy weight woven coated Polyethylene with an
              additional laminated film on the backside
            </p>
            <Link to={`/products#woven-coated-pe-geo-membrane`}>
              <Button size="sm" className="button-gold">
                READ MORE
              </Button>
            </Link>
          </Col>
          <Col xs={12} sm={4}>
            <img
              className="mt-3 mb-3"
              alt="Anchoring Pins"
              src={AnchoringPens}
            />
            <h5>Anchoring Pins</h5>
            <p>
              Anchoring pins of various thickness, gauge and designs suited for
              a range of applications.
            </p>
            <Link to={`/products#anchoring-pins`}>
              <Button size="sm" className="button-gold">
                READ MORE
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>

      <Container className="section" id="about">
        <Row>
          <Col xs={12} sm={6}>
            <h1>Dedicated to serving our clients</h1>
            <p>
              PAR Global, headquartered in Georgia-USA, We are one stop
              manufacturing solution and service provider for Best in Class
              Synthetic and Natural fibre textiles, Industrial and Retail
              Packaging products, Chemicals, Steel and related Accessories. With
              over 100 years of combined experience and success in
              manufacturing, marketing and sales, we bring world class products
              to you as a partner in your success to build a partnership that
              lasts for generations.
            </p>
            <p>
              <span className="text-gold">
                Integrity, ownership, excellence, passion and customer
                satisfaction
              </span>{' '}
              are our core values.
            </p>
          </Col>
          <Col xs={12} sm={6}>
            <img
              src="https://images.ctfassets.net/ktvuei6pywef/Kk8cUhhZIEzdMtTkVk1qQ/bb61e3cc2aae0724ced36bd3dae927c2/ParGlobal_AboutUs_Photo.jpg?h=500"
              alt="par global people working"
              style={{ maxHeight: '450px', objectFit: 'contain' }}
            ></img>
          </Col>
        </Row>
        <div className="mt-5">
          <h1>Why choose Par Global?</h1>
        </div>
        <Row>
          <Col xs={12} sm={4}>
            <img
              alt="we customize for your needs"
              src="https://images.ctfassets.net/ktvuei6pywef/4WLBJ4o6zlJkAmrHzwf1yn/b37e31df52064bf0c32ef90f59adfa18/1_WeCustomizeForYourNeeds.png?h=300"
            />
          </Col>
          <Col xs={12} sm={4}>
            <img
              alt="get your moneys worth"
              src="https://images.ctfassets.net/ktvuei6pywef/3m3aE9GkLi02ohvdTU7kjU/f1fe4301abac13b4156dbed24965cc5f/2_GetYourMoneysWorth.png?h=300"
            />
          </Col>
          <Col xs={12} sm={4}>
            <img
              alt="100+ years of experience"
              src="https://images.ctfassets.net/ktvuei6pywef/1FZsJN0yRLmqw6zHPdZHW9/d200523a546ad32ffda22d732c7c3ac0/100YearsExperience.png?h=300"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={4}>
            <img
              alt="Consistent quality and direction"
              src="https://images.ctfassets.net/ktvuei6pywef/5wreKNLcY3SL52ktdiscqy/ee7f0a7cdf31b6cfeefc688c28dd43a7/4_ConsistentQualityAndDelivery.png?h=300"
            />
          </Col>
          <Col xs={12} sm={4}>
            <img
              alt="Excellent customer service"
              src="https://images.ctfassets.net/ktvuei6pywef/10qvSNnxoHqOAeQNVgE7uZ/4017a8a8a5a8e24547be066ec7ac7e96/5_ExceoptionalCustomerService.png?h=300"
            />
          </Col>
          <Col xs={12} sm={4}>
            <img
              alt="we focus on you"
              src="https://images.ctfassets.net/ktvuei6pywef/6pCOZStMFhervW4iFXFAhX/6b89d4483de0b8b9c14620655839780f/6_WeFocusOnYou.png?h=300"
            />
          </Col>
        </Row>
      </Container>

      <Container className="section" id="news">
        <h1>News & Events</h1>
        <Stack direction="horizontal" gap={3}>
          <div style={{ width: '100px' }}>
            <img
              style={{
                objectFit: 'contain',
              }}
              src={ATALogo}
            />
          </div>

          <div>
            <small className="gray">Feb. 5-8, 2023</small>
            <h5 className="text-gray">Geosynthetics Conference</h5>
            <p>Kansas City Convention Center | Kansas City, MO USA</p>
            <a
              className="text-gold"
              href="https://exhibit.textiles.org/Geo23/Public/eBooth.aspx?IndexInList=0&FromPage=Exhibitors.aspx&ParentBoothID=&ListByBooth=true&BoothID=132974"
              alt="Geosynthetics Conference"
              target="_blank"
              rel="noreferrer"
            >
              Link ⭢
            </a>
          </div>
        </Stack>
      </Container>

      <Container className="section" id="contact">
        <h1>Get in touch</h1>
        <Row className="mt-3">
          <Col md={{ span: 8, offset: 2 }}>
            <Form className="contact-wrapper">
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <FloatingLabel label="Your Name">
                    <Form.Control
                      placeholder="Your Name"
                      id="name"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      style={{ backgroundColor: '#e6e6e6' }}
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col}>
                  <FloatingLabel label="Email">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ backgroundColor: '#e6e6e6' }}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>

              <Form.Group className="mb-3">
                <FloatingLabel label="Subject">
                  <Form.Control
                    placeholder="Subject"
                    id="subject"
                    name="subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    style={{ backgroundColor: '#e6e6e6' }}
                  />
                </FloatingLabel>
              </Form.Group>

              <FloatingLabel label="Message">
                <Form.Control
                  as="textarea"
                  placeholder="Message"
                  id="message"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  style={{ height: '100px', backgroundColor: '#e6e6e6' }}
                />
              </FloatingLabel>
              <div className="text-center">
                <Button
                  className="button-gold mt-3"
                  type="submit"
                  disabled={!name || !message || !subject}
                  href={`mailto:${'sales@parglobal.us'}${params}`}
                  onClick={(e) => e.stopPropagation()}
                  target="_blank"
                >
                  Send
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '10px',
          }}
        >
          <a
            href="https://www.linkedin.com/company/par-global-mktg.co.-inc/about/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={LinkedIn}
              style={{
                height: '60px',
                width: '60px',
                margin: '10px',
                opacity: '0.5',
              }}
            />
          </a>
          <a
            href="https://www.facebook.com/people/Par-Global/100088868582030/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={Facebook}
              style={{
                height: '60px',
                width: '60px',
                margin: '10px',
                opacity: '0.5',
              }}
            />
          </a>
        </div>
      </Container>
    </Layout>
  )
}

export default RootIndex
